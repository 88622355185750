// Angular Files
import { DOCUMENT, Location } from '@angular/common';
import { Component, DoCheck, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

// Angular Material Files
import { MatIconRegistry } from '@angular/material/icon';

// Other External Files
import { Subscription } from 'rxjs';

// Payment Integration Files
import { BasePaymentProcessorComponent, BillingInfoFields, PaymentIntegrationFieldValue } from 'apps/public-portal/src/app/payment-integrations/base/components';
import { CyberSourceService } from 'apps/public-portal/src/app/payment-integrations/cyber-source/service';
import { PaymentMethodTypeEnum } from 'apps/public-portal/src/app/payment-integrations/base/models';
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations/base';

// Teller Online Files
import {
    AuthService,
    CartService,
    InboundRedirectService
} from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import {
    TellerOnlineAppService,
    TellerOnlineErrorHandlerService,
    TellerOnlineSiteMetadataService
} from 'teller-online-libraries/core';
import {
    TellerOnlineMessageService,
    TellerOnlineValidationService
} from 'teller-online-libraries/shared';

// Represents the Flex object loaded from CyberSource
declare const Flex: {
    prototype: { constructor: any },
    microform: any
}

@Component({
    selector: 'app-cyber-source',
    templateUrl: './cyber-source.component.html',
    styleUrls: ['./cyber-source.component.scss'],
    host: {
        class: 'cyber-source'
    }
})
export class CyberSourceComponent extends BasePaymentProcessorComponent implements OnInit, DoCheck, OnDestroy {
    // Public variables
    public cyberSourceFieldsAvailable: boolean = false;
    public creditCardFieldIsDirty: boolean = false;
    public securityCodeFieldIsDirty: boolean = false;
    public requiredAddressFields: BillingInfoFields[] = [
        BillingInfoFields.addressCity,
        BillingInfoFields.addressLine1,
        BillingInfoFields.addressCountry,
        BillingInfoFields.addressState,
        BillingInfoFields.phone
    ]

    // Private variables
    private Microform: {
        createField: any,
        createToken: any
    }

    private _cardNumberFormControl: AbstractControl = null;
    private _securityCodeFormControl: AbstractControl = null;

    // Constants
    private FLEX_MICROFORM_SCRIPT_ID = 'flexmicroform';

    // #region BasePaymentProcessorComponent property overrides
    public set paymentMethodData(paymentMethodData) {
        if (this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        super.paymentMethodData = paymentMethodData;

        // dynamically add all of the cc fields to the controls for the form group
        if (paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
            Object.keys(this.CC_FIELDS).forEach(name => {
                this.paymentDetailsForm.addControl(name, new UntypedFormControl(new PaymentIntegrationFieldValue(null, '', name, this.CC_FIELDS[name])));
            });

            this.paymentDetailsForm.addControl(this.CC_FIELDS.ccname, new UntypedFormControl(paymentMethodData.billingInfo.fullName));
            this.paymentDetailsForm.addControl(this.CC_FIELDS.ccexp, new UntypedFormControl(''));
        }

        if (paymentMethodData) {
            this._formChangeSubscription = this.paymentDetailsForm.valueChanges.subscribe((value) => {
                if (paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
                    paymentMethodData.billingInfo.fullName = value[this.CC_FIELDS.ccname];
                    paymentMethodData.cardExpiry = value[this.CC_FIELDS.ccexp];
                }
            });
        }
    }

    public get paymentMethodData() {
        return super.paymentMethodData;
    }

    // #endregion

    // Subscriptions
    private _formChangeSubscription: Subscription;

    constructor(
        private cyberSourceService: CyberSourceService,
        private errorHandlerService: TellerOnlineErrorHandlerService,
        @Inject(DOCUMENT) private document: Document,
        ngZone: NgZone,
        location: Location,
        appService: TellerOnlineAppService,
        siteMetadataService: TellerOnlineSiteMetadataService,
        inboundRedirectService: InboundRedirectService,
        cartService: CartService,
        authService: AuthService,
        messageService: TellerOnlineMessageService,
        validationService: TellerOnlineValidationService,
        paymentProvider: PaymentProcessorProvider,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        super(appService, ngZone, location, siteMetadataService, inboundRedirectService, cartService, authService, messageService, validationService, paymentProvider, matIconRegistry, domSanitizer);
        this.loading = true;
    }

    //#region OnInit Implementation

    ngOnInit() {
        super.ngOnInit();

        this.paymentProvider.configLoaded$.subscribe(loaded => {
            if (loaded) this._initializeFlexMicroform();
        });
    }

    //#endregion

    //#region DoCheck Implementation

    ngDoCheck(): void {
        super.ngDoCheck();
    }

    //#endregion

    //#region OnDestroy Implementation

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if(this._formChangeSubscription) this._formChangeSubscription.unsubscribe();
    }

    //#endregion

    //#region Event Handlers

    onSubmit_validateAndSubmit = () => {
        if (this.paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
            // If the fields are not dirty, they have not been touched and must be empty.
            // Need to force the validation errors to appear in this case.
            if (!this.creditCardFieldIsDirty) {
                this._cardNumberFormControl = this._updateControl('ccnumber', this.paymentDetailsForm.controls.ccnumber, true, null);
            }

            if (!this.securityCodeFieldIsDirty) {
                this._securityCodeFormControl = this._updateControl('cvv', this.paymentDetailsForm.controls.cvv, true, null);
            }
        }

        let additionalErrors: {[key: string]: string} = {}
        if(this.paymentDetailsForm.controls.ccnumber?.value.error) additionalErrors.ccnumber = this.paymentDetailsForm.controls.ccnumber.value.error;
        if(this.paymentDetailsForm.controls.cvv?.value.error) additionalErrors.cvv = this.paymentDetailsForm.controls.cvv.value.error;

        // Run validation against regular fields + CyberSource fields
        if (this.validationService.runValidation(this.paymentDetailsForm, null, false, additionalErrors)) {
            this.appService.triggerPageLoading('Validating information...');

            if (this.paymentMethodData?.type == PaymentMethodTypeEnum.CreditCard) {
                try {
                    let options = {
                        expirationMonth: this.paymentMethodData.cardExpiry?.slice(0, 2),
                        expirationYear: "20" + this.paymentMethodData.cardExpiry?.slice(2)
                    };

                    this.Microform.createToken(options, (err, token) => {
                        if (err) {
                            this.ngZone.run(() => {
                                this._handleCreateTokenError(err);
                            });
                        } else {
                            this._paymentToken = token;
                            this._submit();
                        }
                    });
                } catch (ex) {
                    this._handleCreateTokenError(ex);
                }
            } else {
                this._paymentToken = "dummy";
                this._submit();
            }
        }
    };

    //#endregion

    //#region BasePaymentProcessorComponent Implementation

    public override async savePaymentMethod() {
        this.appService.triggerPageLoading('Saving information...');

        try {
            var response = await this.cyberSourceService.savePaymentMethod({
                paymentMethodData: this.paymentMethodData,
                paymentToken: this._paymentToken,
                paymentMethodId: this.paymentMethodId
            });

            this.paymentMethodId = response.paymentMethodId;

            this.updateUrl();

            this.processingComplete.emit(response.last4);
        } catch (e) {
            this.processingError.emit(e);
        } finally {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    public override async payCart() {
        this.appService.triggerPageLoading('Processing payment...');

        // Wait for the response.
        await this.cartService.updateCart({
            guestEmailAddress: this.paymentMethodData.billingInfo.email,
            rememberPaymentMethod: this.paymentMethodData.rememberPaymentMethod,
            paymentMethodId: null //unset any previously saved paymentMethodId incase a previous attempt to use a saved method was made
        });

        var proceed = await this.cartService.refreshCart(this._cartGuid, this.paymentMethodData.type);

        if (proceed) {
            try {
                var postPaymentResponse = await this.cyberSourceService.payCart({
                    cartId: this._cartId,
                    paymentMethodData: this.paymentMethodData,
                    paymentToken: this._paymentToken,
                    inboundRedirectSourceId: this.inboundRedirectService.redirectSourceId
                });

                if (postPaymentResponse.cartStatus) {
                    this.processingComplete.emit(postPaymentResponse);
                } else {
                    // Display the appropriate message for the current payment method type
                    let notChargedMessage;
                    switch (this.paymentMethodData.type) {
                        case PaymentMethodTypeEnum.ECheck:
                            notChargedMessage = "Your account has not been charged.";
                            break;
                        case PaymentMethodTypeEnum.CreditCard:
                        default:
                            notChargedMessage = "Your card has not been charged.";
                            break;
                    }
                    this.messageService.notification("Unable to process payment. " + notChargedMessage + " Reason: " +
                        postPaymentResponse.errorMessage, "error", 5000);
                }

            } catch (e) {
                this.processingError.emit(e);
            } finally {
                this.finishedDataEntry(true);
                this.appService.finishPageLoading();
            }
        } else {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    //#endregion

    //#region helpers

    private async _getSessionToken(): Promise<string> {
        return (await this.cyberSourceService.getSessionToken())?.token;
    }

    private async _submit() {
        if (this.forEdit) {
            await this.savePaymentMethod();
        } else {
            await this.payCart();
        }
    }

    private async _initializeFlexMicroform() {
        // check if the script has already been created
        if (!this.document.querySelector("#" + this.FLEX_MICROFORM_SCRIPT_ID)) {
            const node = this.document.createElement('script');
            node.id = this.FLEX_MICROFORM_SCRIPT_ID;
            node.src = this.cyberSourceService.flexMicroformUrl;
            node.type = 'text/javascript';
            node.async = false;

            this.document.getElementsByTagName('head')[0].appendChild(node);
        }

        await this._waitForFlexMicroform();
    }

    /** Every 100ms check if Flex Microform is defined, if it is, configure it, otherwise, repeat */
    private async _waitForFlexMicroform() {
        setTimeout(() => {
            if (typeof Flex != 'undefined' && this.paymentMethodData.type) {
                this._configureFlexMicroform();
            } else {
                this._waitForFlexMicroform();
            }
        }, 100);
    }

    private async _configureFlexMicroform() {
        if (this.paymentMethodData?.type == PaymentMethodTypeEnum.CreditCard) {
            let sessionToken = await this._getSessionToken();
            let flex = new Flex.prototype.constructor(sessionToken);

            this.Microform = flex.microform({styles: this._getCustomStyles()});

            let number = this.Microform.createField('number', {placeholder: '0000 0000 0000 0000'});
            let numberBlurred = false;
            let numberEmpty = true;
            let numberValid = false;
            number.load('#cyber-source-ccnumber');
            number.on('change', data => {
                this.ngZone.run(() => {
                    this.creditCardFieldIsDirty = true;
                    this._cardNumberFormControl = this._updateControl('ccnumber', this.paymentDetailsForm.controls.ccnumber, numberBlurred, data);

                    numberEmpty = data?.empty;
                    numberValid = data?.valid;
                    this.paymentMethodData.cardType = data?.card[0]?.brandedName;
                });
            });

            // this emulates the angular fields as closely as possible,
            // making it so that the validation doesn't kick in until after the first blur event
            number.on('blur', () => {
                if (!numberBlurred) {
                    numberBlurred = true;
                    this._cardNumberFormControl = this._updateControl(
                        'ccnumber',
                        this.paymentDetailsForm.controls.ccnumber,
                        true,
                        {empty: numberEmpty, valid: numberValid}
                    );
                }
            });

            let securityCode = this.Microform.createField('securityCode', {placeholder: '***'});
            let securityCodeBlurred = false;
            let securityCodeEmpty = true;
            let securityCodeValid = false;
            securityCode.load('#cyber-source-cvv');
            securityCode.on('change', data => {
                this.ngZone.run(() => {
                    this.securityCodeFieldIsDirty = true;
                    this._securityCodeFormControl = this._updateControl('cvv', this.paymentDetailsForm.controls.cvv, securityCodeBlurred, data);

                    securityCodeEmpty = data?.empty;
                    securityCodeValid = data?.valid;
                });
            });

            // this emulates the angular fields as closely as possible,
            // making it so that the validation doesn't kick in until after the first blur event
            securityCode.on('blur', () => {
                if (!securityCodeBlurred) {
                    securityCodeBlurred = true;
                    this._securityCodeFormControl = this._updateControl(
                        'cvv',
                        this.paymentDetailsForm.controls.cvv,
                        true,
                        {empty: securityCodeEmpty, valid: securityCodeValid}
                    );
                }
            });
        }

        this.loading = false;
        this.cyberSourceFieldsAvailable = true;
    }

    private _updateControl(controlName, controlValue, isBlurred: boolean, responseData) {
        let control = controlValue?.value;
        if (control) {
            if (responseData?.empty || !responseData?.valid) {
                control.value = '';
                control.error = this.getIntegrationFieldErrorMessage(controlName, !responseData || responseData.empty ? 'required' : 'invalid');
            } else {
                control.error = '';
            }

            if (isBlurred) controlValue?.setValue(control);
        }

        return control;
    }

    private async _handleCreateTokenError(response) {
        this.appService.finishPageLoading();

        if (response?.reason?.toUpperCase() === "CREATE_TOKEN_CAPTURE_CONTEXT_USED_TOO_MANY_TIMES" ||
            response?.reason?.toUpperCase() === "VALIDATION_ERROR" ||
            response?.reason?.toUpperCase() === "CREATE_TOKEN_VALIDATION_SERVERSIDE") {
            this.messageService.notification("Unable to process payment due to expired security token. Please try again.", "error", 5000);
            await this._initializeFlexMicroform();
        } else {
            this.messageService.notification("Unable to process payment. Please refresh the page and try again.", "error", 5000);
            this.errorHandlerService.handleError("Credit card payment could not be submitted because CyberSource token was not generated. Response: " + response.reason);
        }
    }

    // https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ-v2/styling-v2.html
    private _getCustomStyles() {
        return {
            'input': {
                'font-size': '14px',
                'font-family': 'Roboto, sans-serif'
            }
        };
    }

    //#endregion
}
