// Angular Files
import { CommonModule } from '@angular/common';
import { Component, NgModule, Inject } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';

// Angular Material Files
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule, MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";
import { TellerOnlineDialogOptions, TellerOnlineValidationService } from '../../services';
import { DirectivesModule } from '../../directives/directives.module';

@Component({
    selector: 'teller-online-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    host: {
        class: 'dialog'
    }
})
export class TellerOnlineDialogComponent {
    public formData = {};

    constructor(
        public validationService: TellerOnlineValidationService,
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: TellerOnlineDialogOptions,
        private dialogRef: MatLegacyDialogRef<TellerOnlineDialogComponent>
    ) {
    }

    onClick_actionClose(proceed: boolean | {}, form: NgForm) {
        let close = proceed;
        if(this.data.form && proceed) {
            if(!this.validationService.runValidation(form))
                return;
            // update the close value to be the formdata
            close = this.formData;
        }
        this.dialogRef.close(close);
    }
}
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DirectivesModule,
        TellerOnlineIconsModule,
        MatLegacyDialogModule,
        MatLegacyButtonModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule
    ],
    declarations: [ TellerOnlineDialogComponent ],
    exports: [ TellerOnlineDialogComponent ]
})
export class TellerOnlineDialogModule { }
